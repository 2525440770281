import { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AWS_S3, LARAVEL_URL } from "../config/GlobalConfig";
import * as Icon from 'react-bootstrap-icons';
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';
import Form from './formfield/form';
import EmailForm from './agent-handoff/EmailForm';

function ChatbotBox() {

  const navigate = useNavigate();
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const bot = window.atob(params.id).split("-");
  const [config, setConfig] = useState([]);
  const [lastEventTime, setLastEventTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [brand, setBrand] = useState(false);
  const [formfield, setFormfield] = useState([]);

  const [isFormShown, setIsFormShown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [initialMsg, setInitialMsg] = useState(true);
  const [steps, setSteps] = useState([]);
  const [message, setMessage] = useState("");
  const [isTrust, setIsTrust] = useState(false);
  const [isSetTime, isSetTimeOut] = useState("");
  const [stateinputfiled, setstateinputfiled] = useState(false);
  const [calenderFlag, setCalenderFlag] = useState();
  const [isAgentHandoff, setIsAgentHandoff] = useState(false);
  const [unLikeMsg, setUnLikeMsg] = useState([]);

  var parentData = {
    height: "600px",
    width: "450px",
  };
  var ownDomain = (urlParams.get("domain")) ? true : false;
  var sessionID = uuidv4();
  let pageLoad = true;

  if (localStorage.getItem('sessionId')) {
    sessionID = localStorage.getItem('sessionId');
  }
  else {
    localStorage.setItem('sessionId', sessionID);
    formfield?.filter(item => item.collection_method)
  }
  


  useEffect(() => {
    if (pageLoad) {
      pageLoad = false;
      getConfiguration();
      getMessageHistory();
      getFormfieldHistory();
      googlecalender();
      /*
      window.addEventListener("message", (e) => {
        console.log(e);
        if( e.data.type==="set-locators" ){
          if (e.origin === LARAVEL_URL.replace("/api","")){
            ownDomain = true;
          }
          getConfiguration();
          getMessageHistory();
        }
      });
      */
    }
  }, []);

  useEffect(() => {
    // Check for the time elapsed since the last event every second
    const intervalId = setInterval(() => {
      if (lastEventTime) {
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime - lastEventTime;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
        console.log(`Time elapsed since last event: ${elapsedSeconds} seconds`);
        console.log("setIsFormShown", isFormShown);

        // Check if 30 seconds have passed and show the form
        if (elapsedSeconds >= 30) {
          clearInterval(intervalId);
          // if (steps.length > 0) {
          setIsFormShown(true);
          // }
          getFormfieldHistoryafter()

        }
      }
    }, 500);

    
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [lastEventTime]);

  const getConfiguration = () => {
    axios({
      url: `${AWS_S3}/assets/appearance/${bot[2].replace("UI", "")}/${bot[1]}-config.json?time=` + Date.now(),
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {

        setConfig(res.data);
        if (res.data.initial_message !== "" && initialMsg) {
          parentData = {
            height: "200px",
            width: "325px",
          };
        }
        else {
          parentData = {
            height: "125px",
            width: "100px",
          };
        }

        if (res.data.open === "1") {
          parentData = {
            height: "600px",
            width: "450px",
          };
          openChatWindow(true);
        }

        window.parent.postMessage(JSON.stringify(parentData), '*');

        if (res.data.branding === "1") {
          setBrand(true);
        }

        if (ownDomain || res.data.visibility === "public") {
          setIsTrust(true);
        }

        setInitialLoad(true);
        //setTheArray(steps => [...steps, newElement]);
        //setSteps([...steps, firstMsg]);
      })
      .catch((err) => {
        navigate('/');
      });
  }

  const sendMessage = (calenderMessage) => {
    
    let messageData = calenderMessage ? calenderMessage : message

    var msgTime = (new Date()).toLocaleString('en-us', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    
    setSteps(steps => [...steps, {
      id: (steps.length + 1),
      msgType: "message",
      userType: "user",
      message: messageData,
      question: "",
      options: [],
      htmlForm: "",
      msgTime: msgTime,
    }
    ]);
    var prevMessage = messageData;
    setMessage("");
    scrollToLatestChatMessage();
    setLoader(true);
    
    axios({
      url: `${LARAVEL_URL}/chatbot/message`,
      method: "POST",
      data: {
        userSessionId: sessionID,
        chatbotKey: params.id,
        message: prevMessage,
        msgTime: msgTime,
      },
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.response) {
          setSteps(steps => [...steps, {
            id: (steps.length + 1),
            msgType: "message",
            userType: "bot",
            message: res.data.answer,
            question: "",
            options: [],
            htmlForm: "",
            msgTime: msgTime,
            agentHandoffFlag: (typeof res.data.agentHandoffFlag !== 'undefined' && res.data.agentHandoffFlag && calenderFlag.isAgentHandoff==1 )?1:0,
          }
          ]);
          isSetTimeOut(msgTime);
          scrollToLatestChatMessage();
        }
        else {
          navigate('/');
        }
      })
      .catch((err) => {
        navigate('/');
      });
  }

  const getMessageHistory = () => {
    setLoader(true);
    axios({
      url: `${LARAVEL_URL}/chatbot/message-history`,
      method: "POST",
      data: {
        userSessionId: sessionID,
        chatbotKey: params.id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.response) {
          setSteps(res.data.history);
          if( res.data.unLikeArr.length > 0 ){
            setUnLikeMsg([...res.data.unLikeArr]);
          }
        }
      })
      .catch((err) => {
        navigate('/');
      });
  }

  const googlecalender = () => {
    setLoader(true);
    axios(
      {
        url: `${LARAVEL_URL}/google-calender/connect`,
        method: "POST",
        data: {
          userSessionId: sessionID,
          chatbotKey: params.id,
        },
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
      }
    )
      .then((res) => {
        setLoader(false);
        if (res.data)
          setCalenderFlag(res.data)
      })
      .catch((err) => {
        navigate('/');
      });
  }

  const handleInputChange = (event) => {

    setMessage(event.target.value)
    // Update the last event time whenever an input event occurs
    setLastEventTime(new Date());
  };


  const getFormfieldHistory = () => {
    setLoader(true);
    axios({
      url: `${LARAVEL_URL}/chatbot/formfield-history`,
      method: "POST",
      data: {
        userSessionId: sessionID,
        chatbotKey: params.id,
        isEdit: 0,
      },
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {
       
        setLoader(false);
        if (res.data.response) {
          let history = res.data.history;
          setFormfield(history);
          if (history?.length > 0) {
            setIsFormShown(true);
          }
        }
      })
      .catch((err) => {
       
        navigate('/');
      });
  }

  const getFormfieldHistoryafter = () => {
    // setLoader(true);
    axios({
      url: `${LARAVEL_URL}/chatbot/formfield-history`,
      method: "POST",
      data: {
        userSessionId: sessionID,
        chatbotKey: params.id,
        isEdit: 1,
      },
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {
        // console.log("res", res);
        setLoader(false);
        if (res.data.response) {
          let history = res.data.history;

          setFormfield(history);
          if (history?.length > 0) {
            // setIsFormShown(true);
          }
        }
      })
      .catch((err) => {
        console.log("error", err.message);
        navigate('/');
      });
  }

  const scrollToLatestChatMessage = () => {
    $("#custom-chatapp-conversation-group").animate({
      scrollTop: $('#custom-chatapp-conversation-group').prop("scrollHeight")
    }, 1000);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && message.length > 0) {
      sendMessage();
    }
  }

  const handleAppointmentClick = () => {
    // Set the message to "Book Appointment"
    const CalenderMessage = "Book Appointment"
    setMessage(CalenderMessage);
    sendMessage(CalenderMessage);
  };

  const openChatWindow = (status) => {
    setOpen(status);
    if (status) {
      parentData = {
        height: "600px",
        width: "450px",
      };
    }
    else {
      if (config.initial_message !== "" && initialMsg) {
        parentData = {
          height: "200px",
          width: "325px",
        };
      }
      else {
        parentData = {
          height: "125px",
          width: "100px",
        };
      }
    }
    window.parent.postMessage(JSON.stringify(parentData), '*');
    setTimeout(() => {
      scrollToLatestChatMessage();
    }, 1000);
  }

  const closeInitialMsg = (status) => {
    parentData = {
      height: "125px",
      width: "100px",
    };
    window.parent.postMessage(JSON.stringify(parentData), '*');
    setInitialMsg(status);
  }

  const clearChatWindow = () => {
    setSteps([]);
  }
  const isBefore = formfield.length > 0 && formfield?.map(item => item.collection_method);
  // Check if "collect_in_form" is included in the array
  const isCollectInFormafterIncluded = isBefore && isBefore.includes("collect_in_chat");
  const isCollectInFormbeforeIncluded = isBefore && isBefore.includes("collect_in_form")

   useEffect(() => {

    if ((steps.length === 0 && formfield.length > 0 && isCollectInFormbeforeIncluded && isFormShown) || (steps.length > 0 && formfield.length > 0 && isCollectInFormafterIncluded && isFormShown) ) {
      setstateinputfiled(true)
    } else {
      setstateinputfiled(false)
    }

  }, [steps, isCollectInFormbeforeIncluded, isCollectInFormafterIncluded, isFormShown])

  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => 
    `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
  }

  const successAgentHandoff = () => {
    var msgTime = (new Date()).toLocaleString('en-us', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    isSetTimeOut(msgTime);
    setIsAgentHandoff(false);
    setTimeout(() => {
      scrollToLatestChatMessage();
    }, 1000);
  }

  const moveChatToAgent = () => {
    setIsAgentHandoff(true);
    if( calenderFlag.agentHandoffData!=null && calenderFlag.agentHandoffData.handoff_option!=3 ){
      window.open(calenderFlag.agentHandoffData.handoff_details,'Agent','width=700,height=767');
    }
  };

  const thumbMessageAI = (bot_msg_id, status) => {
    if( status ){
      var arr = unLikeMsg;
      var index = arr.indexOf(bot_msg_id);
      arr.splice(index, 1);
      setUnLikeMsg([...arr]);
    }
    else{
      setUnLikeMsg(unLikeMsg => [...unLikeMsg, bot_msg_id]);
    }

    axios({
      url: `${LARAVEL_URL}/chatbot/message-like-status`,
      method: "POST",
      data: {
        userSessionId: sessionID,
        chatbotKey: params.id,
        status: status,
        bot_msg_id: bot_msg_id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
    .then((res) => {
      if (res.data.response) {
        //code
      }
      else {
        navigate('/');
      }
    })
    .catch((err) => {
      navigate('/');
    });
  };

  window.moveChatToAgent = moveChatToAgent;

  return (
    <div className={`custom-chatapp-chatbot custom-chatapp-${(typeof config.position !== 'undefined') ? config.position : ''}`}>
      {
        (initialLoad) ?
          (isTrust) ?
            <h1>
              <div className="chat-board chatbot chat new trending"></div>
              <div className="custom-chatapp-frame-content">
                <div className={`widget-position-right sidebar-position-right custom-chatapp-onlyBubble custom-chatapp-text-white ${(open) ? 'open' : ''}`} id="custom-chatapp-chatContainer">

                  <div className="custom-chatapp-chat no-clip-path chrome moveFromRight-enter-done" style={{ border: `${config.widget_border_width}px solid ${config.widget_border_color}`, borderRadius: `${config.widget_border_radius}px` }} >
                    <div className="custom-chatapp-chat-header project-online custom-chatapp-text-left" style={{ background: `linear-gradient(-61deg, ${config.header_gradient_color_1}, ${config.header_gradient_color_2})` }} >
                      <div className="custom-chatapp-chatbot-head" >
                        <div className="custom-chatapp-chatbot-head-img">
                          {
                            (config.picture !== "" && config.picture !== null) ?
                              <img src={config.picture} height="40" width="40" alt="" className="custom-chatapp-profile-image" />
                              : null
                          }
                        </div>
                        <div className="custom-chatapp-chatbot-head-title">
                          <h2 className="oneline">
                            <span>{(config.display_name !== null) ? config.display_name : config.name}</span>
                            <span className="custom-chatapp-font-width-300">
                              {(config.subHeading !== "") ? config.subHeading : '&nbsp;'}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <button className={`material-icons exit-chat ripple custom-chatapp-tidio-1s5t5ku custom-chatapp-chat-close`} id="custom-chatapp-minimize-button" type="button" onClick={(e) => openChatWindow(false)} aria-label="Minimize" >
                        <Icon.XLg />
                        <span>Minimize</span>
                      </button>
                      <button className={`material-icons exit-chat ripple custom-chatapp-tidio-1s5t5ku custom-chatapp-chat-close custom-chatapp-mr-3`} id="custom-chatapp-minimize-button" type="button" onClick={(e) => clearChatWindow()} title="Reset" >
                        <Icon.ArrowRepeat />
                        <span>Reset</span>
                      </button>
                      {calenderFlag?.googlecalender === 1 &&
                        <button className={`material-icons exit-chat ripple custom-chatapp-tidio-1s5t5ku custom-chatapp-chat-close custom-chatapp-calendar custom-chatapp-mr-3`} id="custom-chatapp-appointment-button" type="button" onClick={(e) => handleAppointmentClick()} title="Create Appointment">
                          <Icon.Calendar3 />
                          <span>Create Appointment</span>
                        </button>
                      }
                      <div className="custom-chatapp-offline-message" >

                      </div>
                    </div>
                    { 
                    (isAgentHandoff && calenderFlag.agentHandoffData!=null && calenderFlag.agentHandoffData.handoff_option==3)?
                    <EmailForm config={config} sessionID={sessionID} params={params} successAgentHandoff={successAgentHandoff} />
                    :
                    <Fragment>
                      {
                        steps.length === 0 && formfield.length > 0 && isCollectInFormbeforeIncluded && isFormShown ?
                        <Fragment>
                        <Form formField={formfield} step={steps} isFormShown={isFormShown} config={config} setIsFormShown={setIsFormShown} setstateinputfiled={setstateinputfiled} />
                        </Fragment>
                        :
                        <Fragment>
                        {steps.length > 0  ? '' :
                          <div id="custom-chatapp-conversation-group" role="log">
                            <div id="messages" aria-live="polite" aria-atomic="false" data-testid="messagesLog">
                              {
                                (config.initial_message !== "") ?
                                  <div className="message message-operator custom-chatapp-font-width-400" style={{ background: config.ai_message_bg_color, color: config.ai_msg_text_color }}>
                                    <span className="message-content" >{config.initial_message}</span>
                                  </div>
                                  : null
                              }
                            </div>

                            {
                              steps.map((step, index) =>
                                <div key={index}>
                                  {
                                    (step.userType === "bot") ?
                                      <>
                                        <div className="custom-chatapp-send-bot-text" >
                                          <span className="custom-chatapp-botText" style={{ background: config.ai_message_bg_color, color: config.ai_msg_text_color }} dangerouslySetInnerHTML={{ __html: linkify(step.message) }}/>
                                        </div>
                                        <div className="custom-chatapp-bot-time">
                                          {step.msgTime}
                                          {/*
                                          <button className="custom-chatapp-thumbs" onClick={()=>thumbMessageAI(step.bot_msg_id,( unLikeMsg.includes(step.bot_msg_id) )?1:0)} style={{ color: config.send_message_button_color }} title={( unLikeMsg.includes(step.bot_msg_id) )?'Unlike':'Like'} type="button">
                                            {
                                              ( unLikeMsg.includes(step.bot_msg_id) )?
                                              <Icon.HandThumbsUp height="15" width="15" />
                                              :
                                              <Icon.HandThumbsUpFill height="15" width="15" />
                                            }
                                          </button>
                                          */}
                                        </div>
                                      </>
                                      :
                                      <>
                                        <div className="custom-chatapp-send-user-text">
                                          <span className="custom-chatapp-sentText" style={{ background: config.user_message_bg_color, color: config.user_msg_text_color }} >
                                            {step.message}
                                          </span>
                                        </div>
                                        <div className="custom-chatapp-user-time">{step.msgTime}</div>
                                      </>
                                  }
                                </div>
                              )
                            }
                            {
                              (loader) ?
                                <div className="custom-chatapp-send-bot-text">
                                  <div className="custom-chatapp-loader custom-chatapp-botText" style={{ background: config.ai_message_bg_color }} id="custom-chatapp-loader">
                                    <span style={{ background: config.ai_msg_text_color }} ></span>
                                    <span style={{ background: config.ai_msg_text_color }} ></span>
                                    <span style={{ background: config.ai_msg_text_color }} ></span>
                                  </div>
                                </div>
                                : null
                            }

                          </div>
                        }
                        </Fragment>
                      }
                      {steps.length > 0 && formfield.length > 0 && isCollectInFormafterIncluded  && isFormShown ? (
                        <Fragment>
                        <Form formField={formfield} step={steps}  isFormShown={isFormShown} config={config} setIsFormShown={setIsFormShown}  scrollToLatestChatMessage={scrollToLatestChatMessage} setstateinputfiled={setstateinputfiled}/>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {
                            steps.length === 0  ? "" :
                              <div id="custom-chatapp-conversation-group" role="log">
                                <div id="messages" aria-live="polite" aria-atomic="false" data-testid="messagesLog">
                                  {
                                    (config.initial_message !== "") ?
                                      <div className="message message-operator custom-chatapp-font-width-400" style={{ background: config.ai_message_bg_color, color: config.ai_msg_text_color }}>
                                        <span className="message-content" >{config.initial_message}</span>
                                      </div>
                                      : null
                                  }
                                </div>

                                {
                                  steps.map((step, index) =>
                                    <div key={index}>
                                      {
                                        (step.userType === "bot") ?
                                          <>
                                            <div className="custom-chatapp-send-bot-text" >
                                              <span className="custom-chatapp-botText" style={{ background: config.ai_message_bg_color, color: config.ai_msg_text_color }} dangerouslySetInnerHTML={{ __html: linkify(step.message) }}/>
                                            </div>
                                            <div className="custom-chatapp-bot-time">
                                              {step.msgTime}
                                              {/*
                                              <button className="custom-chatapp-thumbs" onClick={()=>thumbMessageAI(step.bot_msg_id,( unLikeMsg.includes(step.bot_msg_id) )?1:0)} style={{ color: config.send_message_button_color }} title={( unLikeMsg.includes(step.bot_msg_id) )?'Unlike':'Like'} type="button">
                                                {
                                                  ( unLikeMsg.includes(step.bot_msg_id) )?
                                                  <Icon.HandThumbsUp height="15" width="15" />
                                                  :
                                                  <Icon.HandThumbsUpFill height="15" width="15" />
                                                }
                                              </button>
                                              */}
                                            </div>
                                          </>
                                          :
                                          <>
                                            <div className="custom-chatapp-send-user-text">
                                              <span className="custom-chatapp-sentText" style={{ background: config.user_message_bg_color, color: config.user_msg_text_color }} >
                                                {step.message}
                                              </span>
                                            </div>
                                            <div className="custom-chatapp-user-time">{step.msgTime}</div>
                                          </>
                                      }
                                    </div>
                                  )
                                }
                                {
                                  (loader) ?
                                    <div className="custom-chatapp-send-bot-text">
                                      <div className="custom-chatapp-loader custom-chatapp-botText" style={{ background: config.ai_message_bg_color }} id="custom-chatapp-loader">
                                        <span style={{ background: config.ai_msg_text_color }} ></span>
                                        <span style={{ background: config.ai_msg_text_color }} ></span>
                                        <span style={{ background: config.ai_msg_text_color }} ></span>
                                      </div>
                                    </div>
                                    : null
                                }

                              </div>
                          }
                        </Fragment>
                      )
                      }
                    </Fragment>
                    }
                    {stateinputfiled || (isAgentHandoff && calenderFlag.agentHandoffData!=null && calenderFlag.agentHandoffData.handoff_option==3)? "" :
                      <div className="custom-chatapp-input-group ">
                        <div className="drag-active-wrapper footer-input-wrapper">
                          <>
                            <input type="text" id="custom-chatapp-chat-input" rows="1"
                              placeholder={config.placeholder}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                              value={message}
                            />

                            <button id="SentButton" disabled={(message.length > 0) ? false : true} onClick={()=>sendMessage()} className="custom-chatapp-send-icon" style={{ color: config.send_message_button_color }} title="SentButton" type="button">
                              <Icon.SendFill />
                            </button>
                          </>
                        </div>
                      </div>
                    }
                    {
                      (brand) ?
                        <div className="custom-chatapp-branding">
                          Powered by <b>{(config.company !== "" && config.company !== null) ? config.company : 'PrimeCX'}</b>
                        </div>
                        : null
                    }
                  </div>

                </div>
                <div id="custom-chatapp-chat-button" data-testid="widgetButton" className={`chat-closed mobile-size__medium ${(open) ? 'custom-chatapp-clicked' : ''}`}>
                  {
                    (config.initial_message !== "" && initialMsg) ?
                      <div className="custom-chatapp-initial-message">

                        <div className="custom-chatapp-chat-popup-img">
                          {
                            (config.picture !== "" && config.picture !== null) ?
                              <img src={config.picture} height="30" width="30" alt="" onClick={(e) => openChatWindow(true)} className="custom-chatapp-profile-image" />
                              : null
                          }
                        </div>
                        <div className="custom-chatapp-chat-popup-title">
                          <h4 className="custom-chatapp-initial-head" onClick={(e) => openChatWindow(true)} >
                            <span>{(config.display_name !== null) ? config.display_name : config.name}</span>
                            <p onClick={(e) => openChatWindow(true)} >{config.initial_message}</p>
                          </h4>
                        </div>
                        <button className={`material-icons`} id="custom-chatapp-minimize-button" type="button" onClick={(e) => closeInitialMsg(false)} aria-label="Minimize" >
                          <Icon.XLg />
                        </button>
                      </div>
                      : null
                  }
                  {
                    (config.icon !== "" && config.icon !== null) ?
                      <button type="button"
                        id="custom-chatapp-img-body"
                        className="chrome"
                        tabIndex="0"
                        aria-label="Open chat widget"
                        onClick={(e) => openChatWindow(true)}
                      >
                        <img src={config.icon} height="60" width="60" alt="" className="custom-chatapp-chat-icon" />
                      </button>
                      :
                      <>
                        <div className="buttonWave"></div>
                        <button type="button"
                          id="custom-chatapp-button-body"
                          className="chrome"
                          tabIndex="0"
                          aria-label="Open chat widget"
                          onClick={(e) => openChatWindow(true)}
                        >
                          <Icon.ChatFill className="custom-chatapp-text-white material-icons type1 for-closed active" />
                        </button>
                      </>
                  }
                </div>
              </div>
            </h1>
            :
            <div className="custom-chatapp-frame-content custom-chatapp-404" style={{ background: `url('/assets/images/404.jpg') no-repeat center center #fff` }}>
            </div>
          : null
      }
    </div>
  );
}

export default ChatbotBox;