import React from 'react';

const TextInput = (prop) => {
  return (
    <>
      <label>
        {prop.items.field_label} <span className="custom-chatapp-text-red" >{prop.items.is_required === 0 ? '' : '*' }</span>
        <input
          type="text"
          placeholder={prop.items.field_placeholder}
          name={prop.items.field_label}
          required={prop.items.is_required === 0 ? false : true}
          onChange={prop.onChange}
          id={prop.id}
          className="chat-input"
        />
      </label>
    </>
  );
};

export default TextInput;