import { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { LARAVEL_URL } from "../../config/GlobalConfig";
import TextBox from './textbox';
import Emails from './emails';
import Number from './number';
import * as Icon from 'react-bootstrap-icons';
import Checkbox from './checkbox';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const Form = (props) => {


  const isBefore = props.formField.length > 0 && props.formField?.map(item => item.collection_method);
  const isCollectInFormbeforeIncluded = isBefore && isBefore.includes("collect_in_form");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [formFieldInput, setFormFieldInput] = useState();
  var sessionID = uuidv4();


  if (localStorage.getItem('sessionId')) {
    sessionID = localStorage.getItem('sessionId');
  }
  else {
    localStorage.setItem('sessionId', sessionID);
  }

  const setInputField = (items) => {
    if (items.field_type == "text") {
      return <TextBox items={items} key={items.id} id={items.field_type + "_" + items.id} onChange={handleValueChange} /> //id={items.field_type+"_"+items.id}
    }
    if (items.field_type == "number") {
      return <Number items={items} key={items.id} id={items.field_type + "_" + items.id} onChange={handleValueChange} /> //id={items.field_type+"_"+items.id}
    }
    if (items.field_type == "email") {
      return <Emails items={items} key={items.id} id={items.field_type + "_" + items.id} onChange={handleValueChange} /> //id={items.field_type+"_"+items.id}
    }
    if (items.field_type == "checkbox") {
      return <Checkbox items={items} key={items.id} id={items.field_type + "_" + items.id} onChange={handleValueChange} /> //id={items.field_type+"_"+items.id}
    }
  }

  const handleValueChange = (e) => {
    setFormFieldInput((v) => ({ ...v, [e.target.id]: e.target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // props.setIsFormShown(false);
    var dataForm = {
      collectedLeads: formFieldInput,
      userSessionId: sessionID,
      chatbotKey: params.id,
    };
    if (typeof props.setSteps !== "undefined") {
      dataForm.lead_collected_method = 1;
    }
    setLoader(true);
    axios({
      url: `${LARAVEL_URL}/chatbot/save/formfield`,
      method: "POST",
      data: dataForm,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((res) => {
        setLoader(false);
        props.setIsFormShown(false);
        props.setstateinputfiled(false);
        if (typeof props.setSteps !== "undefined") {
          var msgTime = (new Date()).toLocaleString('en-us', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });

          props.setSteps(step => [...step, {
            id: (props.step.length + 1),
            msgType: "message",
            userType: "bot",
            message: res.data.message,
            question: "",
            options: [],
            htmlForm: "",
            msgTime: msgTime,
          }
          ]);
          setTimeout(() => {
            props.scrollToLatestChatMessage();
          }, 2000);
        }
        // props.handleData(1);
      })
      .catch((err) => {
        navigate('/');
      });
  }
 
  return (
    <>
      <form className="lead-form" onSubmit={handleSubmit}>
        {isCollectInFormbeforeIncluded ?
          <div style={{  color: "gray", fontSize: "16px", fontWeight: 400, padding:"10px 0" }}>
            Hi 👋, Please fill out the form below to start chatting
          </div> : ""}
        {
          props.formField && props.formField.map((items) => {
            return setInputField(items);
          })
        }
        {isCollectInFormbeforeIncluded ?
          <button className="btn_lead_form submit-button" style={{ background: props.config.send_message_button_color }} type="submit">Start chat</button> :
          <button className="btn_lead_form submit-button" style={{ background: props.config.send_message_button_color }} type="submit">Submit</button>
        }
      </form>
    </>
  );
};

export default Form;