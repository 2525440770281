import React from 'react';

const Checkbox = (prop) => {
  return (
    <>
      <div className="custom-chatapp-form-group">
          <input
            type="checkbox"
            className="custom-chatapp-checkbox chat-input"
            name={prop.items.field_label}
            required={prop.items.is_required}
            onChange={prop.onChange}
            id={prop.id}
          />
          <label className="custom-chatapp-label" htmlFor={prop.id} >{prop.items.field_label} <span className="custom-chatapp-text-red" >{prop.items.is_required === 0 ? '' : '*' }</span></label>
      </div>
    </>
  );
};

export default Checkbox;