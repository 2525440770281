import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { LARAVEL_URL } from "../../config/GlobalConfig";
import * as Icon from 'react-bootstrap-icons';
import axios from "axios";
import $ from "jquery";

const EmailForm = (props) => {
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formFieldInput, setFormFieldInput] = useState();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setFormFieldInput((v) => ({ ...v, [e.target.id]: e.target.value }))
    }

    const handleAttachment = (e) => {
        var ext = e.target.value.split('.').pop().toLowerCase();
        var sizeLimit = (1024 * 2000);
        if($.inArray(ext, ['pdf','doc','docx','png','jpeg','jpg']) == -1) {
            $('#agent-handoff-attachment').val('');
        }
        else if( e.target.files[0].size > sizeLimit ){
            $('#agent-handoff-attachment').val('');
        }
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        /*var dataForm = {
            collectedData: formFieldInput,
            attachment: ($('#agent-handoff-attachment').val()!=="")?$('#agent-handoff-attachment')[0].files[0]:null,
            userSessionId: props.sessionID,
            chatbotKey: props.params.id,
        };*/
        const formData = new FormData();
        formData.append('collectedData',JSON.stringify(formFieldInput));
        formData.append('attachment',($('#agent-handoff-attachment').val()!=="")?$('#agent-handoff-attachment')[0].files[0]:null);
        formData.append('userSessionId',props.sessionID);
        formData.append('chatbotKey',props.params.id);

        setLoader(true);
        axios({
            url: `${LARAVEL_URL}/chatbot/ticket/save`,
            method: "POST",
            data: formData,
            processData: false,
            contentType: false,
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
        })
        .then((res) => {
            setLoader(false);
            if (res.data.response) {
                setSuccess(true);
                setTimeout(() => {
                    props.successAgentHandoff();
                }, 3000);
            }
        })
        .catch((err) => {
            navigate('/');
        });
    }

    return (
        (success)?
        <>
            <div className="custom-chatapp-agent-handoff-success">
                <div className="custom-chatapp-agent-handoff-success-sec" >
                    <div className="custom-chatapp-agent-handoff-success-icon" >
                        <Icon.CheckCircle height="100" width="100" />
                    </div>
                    <div className="custom-chatapp-agent-handoff-success-msg" >
                        <div className="custom-chatapp-agent-handoff-success-title">Success</div>
                        <div className="custom-chatapp-agent-handoff-success-desc">Thank you for reaching out to us. Your inquiry has been successfully submitted. <br />A member of our support team will be in touch with you shortly to assist further. We appreciate your patience.</div>
                    </div>
                </div>
            </div>
        </>
        :
        <>
        <form className="lead-form"  onSubmit={handleSubmit} >
            <div style={{  color: "gray", fontSize: "16px", fontWeight: 400, padding:"10px 0" }}>
                Opps 🧐, it appears I'm unable to provide an answer to your question. Please take a moment to fill out the form below and our support team will reach out to you on your email shortly.
            </div>
            <div className="custom-chatapp-form-group">
                <label htmlFor="agent-handoff-first-name" className="custom-chatapp-m-0" >First Name <span className="custom-chatapp-text-red" >*</span></label>
                <input type="text" className="form-control chat-input" id="agent-handoff-first-name" placeholder="Enter first name" maxLength="80" onChange={handleInputChange} required />
            </div>
            <div className="custom-chatapp-form-group">
                <label htmlFor="agent-handoff-last-name" className="custom-chatapp-m-0">Last Name <span className="custom-chatapp-text-red" >*</span></label>
                <input type="text" className="form-control chat-input" id="agent-handoff-last-name" placeholder="Enter last name" maxLength="80" onChange={handleInputChange} required />
            </div>
            <div className="custom-chatapp-form-group">
                <label htmlFor="agent-handoff-email" className="custom-chatapp-m-0" >Email address <span className="custom-chatapp-text-red" >*</span></label>
                <input type="email" className="form-control chat-input" id="agent-handoff-email" placeholder="Enter email address" maxLength="80" onChange={handleInputChange} required />
            </div>
            <div className="custom-chatapp-form-group">
                <label htmlFor="agent-handoff-query" className="custom-chatapp-m-0" >Query <span className="custom-chatapp-text-red" >*</span></label>
                <input type="text" className="form-control chat-input" id="agent-handoff-query" placeholder="Enter query" maxLength="500" onChange={handleInputChange} required />
            </div>
            <div className="custom-chatapp-custom-file-sec">
                <label className="custom-chatapp-m-0" >Attachment</label>
                <input type="file" className="custom-file-input chat-input" id="agent-handoff-attachment" onChange={handleAttachment} accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg" />
                <div className="custom-chatapp-text-red custom-chatapp-note" >Note: Accept png, jpg, jpeg, doc, pdf and less then 2 MB size</div>
            </div>
            <div className="custom-chatapp-mt-2">
                <button className="btn_lead_form submit-button" disabled={loader} style={{ background: props.config.send_message_button_color }} type="submit">
                    {
                    (loader)?
                        <div className="custom-chatapp-loader" style={{ background: 'transparent' }} id="custom-chatapp-loader">
                            <span style={{ background: '#ffffff' }} ></span>
                            <span style={{ background: '#ffffff' }} ></span>
                            <span style={{ background: '#ffffff' }} ></span>
                        </div>
                        :
                        <>
                        Submit
                        </>
                    }
                </button>
            </div>
        </form>
        </>
    );
};

export default EmailForm;