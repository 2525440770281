import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import ChatbotBox  from './components/ChatbotBox';
import ChatbotShare  from './components/ChatbotShare';
import Page404 from './components/Page404';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/chatbot/embed/:id" element={<ChatbotBox />} />
          <Route path="/chatbot/share/:id" element={<ChatbotShare />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
